$(document).ready(function () {
  const imagesHTMLElms = document.querySelectorAll("img");
  imagesHTMLElms.forEach((elm) => {
    elm.classList.add("lozad");
    // $(elm).attr("data-placeholder-background", `#c4c4c4`);
  });
  const observer = lozad(".lozad", {
    load: (el) => {
      // if (el.dataset.backgroundImage) {
      //   el.style.backgroundImage = `url(${el.dataset.backgroundImage}`;
      //   el.classList.add("fade");
      // }
      el.src = el.dataset.original;
      el.onload = function () {
        el.classList.add("fadeCustom");
        $("img:not(.info-detail-product)").css("background", "transparent");
      };
      if ($(el).hasClass("logo-brand")) {
        $(el).css("filter", "blur(0)");
      }
    },
  });
  observer.observe();
  // passing a `NodeList` (e.g. `document.querySelectorAll()`) is also valid
});
